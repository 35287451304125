import * as React from 'react';

import styles from "./ForthScreen.module.css"
import Button from '../components/Button/Button';
import FAQItem from '../components/NewQuestions/Questions';
 const ForthScreen=()=>{

 
  return <>

      <div className={styles.title} id="שאלות תשובות" itemProp="mainEntity" itemScope itemType="https://schema.org/WebPage">
  זמן לענות על השאלות שלכם
</div>
<FAQItem question="אתם מגיעים לכל מקום בארץ?" answer="כן , השירות בפריסה ארצית." />
<FAQItem question="איך אני יודע להזמין את הכמות שמתאימה לאירוע שלי ?" answer="ובכן ישלנו שיטה לחשב כמויות בערך שתתאים בצורה הכי טובה , לאחר שאלות ההתאמה נבנה ביחד את התפריט המתאים ביותר עבורכם." />
<FAQItem question="אתם כשרים?" answer="אין לנו עדיין תעודת כשרות מהסיבה שאנחנו לא זכאיות לכך בגלל שאנחנו עסק בייתי. אבל בכל זאת חשוב שתדעו שכל האוכל שלנו מוכן בסביבת עבודה כשרה ונקייה , בחומרי גלם כשרים עם תעודות ובהפרדה מלאה " />
<FAQItem question="אם אני רוצה להזמין שולחן בופה ל15 איש?" answer="שירות  הקייטרינג שלנו עד בית הלקוח הוא למינימום הזמנה של 30 סועדים, איך יש אופציה לא פחות טובה של מגשי אירוח שתוכלו להזמין ולקבל משלוח עד אליכם :) ." />
<FAQItem question="האם עיצוב השולחן כולל בשירות?" answer="כמובן , השולחן שלנו מגיע מעוצב כחלק מהקונספט שלנו. " />
<FAQItem question="האם ניתן להוסיף שתיה?" answer="בטח, כחלק מהשירות שלנו אנו נוכל לפנות לספק חיצוני של בר שתיה קרה / אלכוהול לפי בקשתכם. " />
<FAQItem question="האם ניתן להוסיף אנשי צוות כגון מלצרים למהלך האירוע?" answer="כן, יש אפשרות להוסיף בתשלום מלצרים ואנשי צוות לאירוע שלכם. כמובן שאנחנו עובדות עם האנשים המקצועיים ביותר" />
<FAQItem question="אתם דואגים גם לעריכה?" answer="בטח, אנחנו עובדות עם כלים חד פעמיים מתקלים ( צלחות וסכום) אם תרצו, בהזמנה מראש כמובן, יש אופציה לעבוד עם כלים רב פעמיים בתוספת תשלום." />

    <Button text="אשמח לדעת עוד כמה פרטים"/>
 </>
}
export default ForthScreen
